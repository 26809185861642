<template>
    <div class="hotel-contract-form">
    <h4>Update Contract: {{ hotelname_header }}</h4>
        <div class="card" style="margin-bottom: 1rem">
            <div class="p-field contract-input-bar">
                <label>Contract List:</label>
                <form @submit.prevent="contractform_type == 'add' ? addHotelContract() : updateHotelContract()">
                    <InputText id="contract_name" v-model="contract_form.contract_name" type="text" placeholder="Contract name" :class="{'p-invalid':v_contractform$.contract_name.$error}"/>
                    <Button label="Add" v-if="contractform_type == 'add'" type="submit" class="p-button-sm p-button-success" icon="pi pi-save" />
                    <span v-if="contractform_type == 'update'">
                        <Button icon="pi pi-check" type="submit" class="small-datatable-button p-button-success p-button-rounded p-mr-1" />
                        <Button icon="pi pi-times" class="small-datatable-button p-button-secondary p-button-rounded" @click="cancelContractUpdate()" />
                    </span>
                </form>
            </div>

            <DataTable
                :value="hotelContractList"
                responsiveLayout="scroll"
                scrollHeight="245px"
                selectionMode="single"
                v-model:selection="selectedHotelContract"
                @rowSelect="onHotelContractRowSelect"
                dataKey="id"
            >
                <Column field="id" header="ID" :style="{'min-width':'60px'}"></Column>
                <Column field="contract_name" header="Contract name"></Column>

                <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                    <template #body="slotProps">
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="editContract(slotProps.data)"/>
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteHotelContract(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </div>
        
        <div class="card" style="margin-bottom: 1rem">
            <div class="p-field contract-input-bar">
                <label>Contract Validity:</label>
                <form @submit.prevent="contractperiodform_type == 'add' ? addContractPeriod() : updateContractPeriod()">
                    <div class="p-fluid p-grid p-field">
                        <label class="p-col" for="valid_from">From:</label>
                        <div class="p-col">
                            <Calendar id="navigators" style="width: 18rem; height: 2.2rem" v-model="contract_period_form.valid_from" :manualInput="false" :monthNavigator="true" :yearNavigator="true" yearRange="2000:2030" dateFormat="dd-M-yy" :showIcon="true" :class="{'p-invalid':v_contractperiod$.valid_from.$error || isDateValid.isInvalid}"/>
                            <small v-if="v_contractperiod$.valid_from.$error" class="p-error">{{v_contractperiod$.valid_from.required.$message}}</small>
                            <small v-if="isDateValid.isInvalid" class="p-error">{{isDateValid.message}}</small>
                        </div>
                        <label class="p-col" for="valid_to" >To:</label>
                        <div class="p-col">
                            <Calendar id="navigators" style="width: 18rem; height: 2.2rem" v-model="contract_period_form.valid_to" :manualInput="false" :monthNavigator="true" :yearNavigator="true" yearRange="2000:2030" dateFormat="dd-M-yy" :showIcon="true" :class="{'p-invalid':v_contractperiod$.valid_to.$error || isDateValid.isInvalid}"/>
                            <small v-if="v_contractperiod$.valid_to.$error" class="p-error">{{v_contractperiod$.valid_to.required.$message}}</small>
                            <small v-if="isDateValid.isInvalid" class="p-error">{{isDateValid.message}}</small>
                        </div>

                        <div class="p-col">
                            <Button label="Add" v-if="contractperiodform_type == 'add'" type="submit" style="width: 5rem" class="p-button-sm p-button-success" icon="pi pi-save" :disabled="selectedHotelContract.id == 0"/>
                            <span v-if="contractperiodform_type == 'update'">
                                <Button icon="pi pi-check" type="submit" class="small-datatable-button p-button-success p-button-rounded p-mr-1" />
                                <Button icon="pi pi-times" class="small-datatable-button p-button-secondary p-button-rounded" @click="cancelContractPeriodUpdate()" />
                            </span>
                        </div>
                    </div>
                </form>
            </div>
            <div class="p-grid p-field">
                <div class="p-col">
                    <label class="p-mr-5">Contract:</label>
                    <span style="font-weight: 500">{{selectedHotelContract.contract_name}}</span>
                </div>
            </div>
            <DataTable
                :value="contractPeriodList"
                responsiveLayout="scroll"
                scrollHeight="245px"
                selectionMode="single"
                v-model:selection="selectedContractPeriod"
                @rowSelect="onContractPeriodRowSelect"
                dataKey="id"
            >
                <Column field="id" header="Period ID" :style="{'min-width':'30px'}"></Column>
                <Column field="valid_from" header="From" :style="{'min-width':'60px'}"></Column>
                <Column field="valid_to" header="To" :style="{'min-width':'60px'}"></Column>

                <Column :exportable="false" :style="{'min-width':'90px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                    <template #body="slotProps">
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-outlined p-button-success small-datatable-button p-mr-2" @click="editContractPeriod(slotProps.data)"/>
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteContractPeriod(slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </div>
        
        <div class="card" style="margin-bottom: 2rem">
            <div class="p-field">
                <label class="p-mr-3">Room Rate:</label>
                <span style="font-weight: 500">
                    {{ contractRateTitle }}
                </span>
                <div class="p-grid p-field p-mt-3">
                    <label class="p-col-fixed" for="season">Season:</label>
                    <div class="p-fluid p-col-4">
                        <Dropdown v-model="selectedSeason" :options="seasonalDropDown" optionLabel="season" />
                    </div>
                    <div class="p-col" style="text-align: right">
                        <Button label="Add Room" @click="addMoreRoom()" class="p-button-sm" :disabled="addMoreRoomButton_Disable"/>
                    </div>
                </div>
            </div>

            <DataTable class="hotel-rate custom-rate-input" responsiveLayout="scroll"
                :value="roomrate"
                datakey="id"
                showGridlines
            >
                <ColumnGroup type="header">                
                    <Row>
                        <!-- <Column :rowspan="3"></Column> -->
                        <Column header="Room Type/Category" :rowspan="3" :style="{'width':'320px'}" />
                    </Row>
                    <Row>
                        <Column header="SGL" :colspan="2" />
                        <Column header="DBL/TWN" :colspan="2" />
                        <Column header="TRPL" :colspan="2" />
                        <Column :rowspan="2" />
                    </Row>
                    <Row>
                        <Column header="FIT" :style="{'width':'90px'}"/>
                        <Column header="GIT" :style="{'width':'90px'}"/>
                        <Column header="FIT" :style="{'width':'90px'}"/>
                        <Column header="GIT" :style="{'width':'90px'}"/>
                        <Column header="FIT" :style="{'width':'90px'}"/>
                        <Column header="GIT" :style="{'width':'90px'}"/>
                    </Row>
                </ColumnGroup>
                <!-- <Column selectionMode="multiple" headerStyle="width: 3em"></Column> -->
                <Column field="room_type" class="p-fluid">
                    <template #body="slotProps" >
                        <InputText v-model="slotProps.data[slotProps.column.props.field]" @input="onValueInput(slotProps.data, slotProps.index)" />
                    </template>
                </Column>
                <Column field="sgl_fit" class="p-fluid">
                    <template #body="slotProps">
                        <InputNumber v-model="slotProps.data[slotProps.column.props.field]" @input="onValueInput(slotProps.data, slotProps.index)" />
                    </template>
                </Column>
                <Column field="sgl_git" class="p-fluid">
                    <template #body="slotProps">
                        <InputNumber v-model="slotProps.data[slotProps.column.props.field]" @input="onValueInput(slotProps.data, slotProps.index)" />
                    </template>
                </Column>
                <Column field="dbl_fit" class="p-fluid">
                    <template #body="slotProps">
                        <InputNumber v-model="slotProps.data[slotProps.column.props.field]" @input="onValueInput(slotProps.data, slotProps.index)" />
                    </template>
                </Column>
                <Column field="dbl_git" class="p-fluid">
                    <template #body="slotProps">
                        <InputNumber v-model="slotProps.data[slotProps.column.props.field]" @input="onValueInput(slotProps.data, slotProps.index)" />
                    </template>
                </Column>
                <Column field="trp_fit" class="p-fluid">
                    <template #body="slotProps">
                        <InputNumber v-model="slotProps.data[slotProps.column.props.field]" @input="onValueInput(slotProps.data, slotProps.index)" />
                    </template>
                </Column>
                <Column field="trp_git" class="p-fluid">
                    <template #body="slotProps">
                        <InputNumber v-model="slotProps.data[slotProps.column.props.field]" @input="onValueInput(slotProps.data, slotProps.index)" />
                    </template>
                </Column>
                <Column :exportable="false" :style="{'min-width':'60px', 'padding':'0.4rem 0.5rem', 'text-align':'right'}">
                    <template #body="slotProps">
                        <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button p-mr-2" @click="confirmDeleteRoomRate(slotProps.data, slotProps.index)" />
                    </template>
                </Column>
            </DataTable>

            <div class="p-fluid p-grid p-mt-1">
                <div class="p-col-fixed p-ml-3">
                    <label>Extra bed: </label>
                </div>
                <div class="p-col-fixed">
                    <InputNumber v-model="extrabed" />
                </div>
                <div class="p-col" style="text-align: right">
                    <span style="font-weight: 500">Room rates are in USD</span>
                </div>
            </div>
                       
            <div style="margin: 0.5rem 0rem">
                <Button label="Save Room Rate" @click="saveRoomRate()" class="p-button-sm p-button-success p-mr-2" icon="pi pi-save" :disabled="saveRoomRateButton_Disable"/>                
            </div>
            
            <hr style="border: 0; border-top: 1px solid var(--surface-d);"/>

            <div class="p-grid p-field" style="align-items: center">
                <div class="p-col">
                    <span v-if="selectedSeason">{{selectedSeason.season}} Period:</span>
                </div>
                <div class="p-col" style="text-align: right">
                    <Button label="Add Date" @click="addMorePeriodDate()" class="p-button-sm" :disabled="saveRoomRateButton_Disable" />
                </div>
            </div>
            <div class="p-fluid p-grid p-field" v-for="(s_period, index) of season_periods" :key="s_period.id">
                <label class="p-col-fixed" for="valid_from">From:</label>
                <div class="p-col">
                    <Calendar :id="s_period.id" style="height: 2.2rem" v-model="s_period.season_from" @date-select="onSeasonPeriodChanged(index)" :manualInput="false" :monthNavigator="true" :yearNavigator="true" yearRange="2000:2030" dateFormat="dd-M-yy" :showIcon="true" :class="{'p-invalid':periodDateValid.idx == index}"/>
                    <small v-if="periodDateValid.idx == index" class="p-error">{{periodDateValid.message}}</small>
                </div>
                <label class="p-col-fixed" for="valid_to" >To:</label>
                <div class="p-col">
                    <Calendar :id="s_period.id" style="height: 2.2rem" v-model="s_period.season_to" @date-select="onSeasonPeriodChanged(index)" :manualInput="false" :monthNavigator="true" :yearNavigator="true" yearRange="2000:2030" dateFormat="dd-M-yy" :showIcon="true" :class="{'p-invalid':periodDateValid.idx == index}"/>
                    <small v-if="periodDateValid.idx == index" class="p-error">{{periodDateValid.message}}</small>
                </div>
                <div class="p-col-fixed">
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-danger small-datatable-button" @click="confirmDeleteSeasonPeriod(s_period, index)" />
                </div>
            </div>
        </div>
    </div>
    <ConfirmDialog></ConfirmDialog>
</template>

<script>
import { ref, reactive, computed, onMounted, watchEffect } from 'vue'
import { useConfirm } from "primevue/useconfirm";
import { useRoute } from 'vue-router';
import { useToast } from "primevue/usetoast";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween'
dayjs.extend(isBetween)

import HotelService from '../service/HotelService'

export default {
    setup() {
        const confirm = useConfirm()
        const route = useRoute()
        const toast = useToast()
        const hotel_id = route.query.hotelid
        let hotelname_header = ref('')

        const hotelService = ref(new HotelService())

        onMounted(() => {
            getHotelName()
            getHotelContract()
        })

        //Methods
        const getHotelName = () => {
            hotelService.value.getHotelName(hotel_id).then(data =>  {
                if(data.errorResponse)  {
                    hotelname_header.value = data.errorResponse
                } else {
                    hotelname_header.value = data
                }
            })
        }

        //==============CONTRACT LIST==============
        let hotelContractList = ref([])
        let selectedHotelContract = ref({id: 0, contract_name: ''})
        let contract_form = reactive({})
        let contractform_type = ref('add')

        const getHotelContract = () => {
            hotelService.value.getContract(hotel_id).then((data) => {
                hotelContractList.value = data
            })
        }

        const onHotelContractRowSelect = () => {
            cancelContractUpdate()
            getContractPeriod(selectedHotelContract.value.id)

            cancelContractPeriodUpdate()
            selectedContractPeriod.value = {}

            contractRateTitle.value = ''
            seasonalDropDown.value.length = 0
            roomrate.value.length = 0
            extrabed.value = 0
            saveRoomRateButton_Disable.value = true
        }
        
        const addHotelContract = () =>{
            if(validateContractForm()) {
                contract_form.hotel_id = hotel_id
                hotelService.value.addHotelContract(contract_form).then(data => {
                    if(!data.errorResponse) {
                        if(data.status == 400) {
                            toast.add({severity:'warn', summary: 'Add Contract Error ', detail: data.message, life: 5000}); 
                        } else {
                            getHotelContract()
                            initContractForm()
                            v_contractform$.value.$reset()
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Add Contract Error ' + data.status, detail: data.errorResponse, life: 5000}); 
                    }
                })
            }
        }

        const updateHotelContract = () => {
            if(validateContractForm()) {
                const contract_id = selectedHotelContract.value.id
                if(selectedHotelContract.value.contract_name != contract_form.contract_name) {
                    hotelService.value.updateHotelContract(contract_id, {contract_name: contract_form.contract_name, hotel_id: hotel_id}).then(data => {
                        if(!data.errorResponse) {
                            if(data.status == 400) {
                                toast.add({severity:'warn', summary: 'Update Contract Error ', detail: data.message, life: 5000}); 
                            } else {
                                getHotelContract()
                                initContractForm()
                                v_contractform$.value.$reset()
                                contractform_type.value = 'add'
                            }
                        } else {
                            toast.add({severity:'warn', summary: 'Update Contract Error ' + data.status, detail: data.errorResponse, life: 5000}); 
                        }
                    })
                }
            }
        }

        const confirmDeleteHotelContract = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.contract_name +'" from this hotel?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const contract_id = data.id
                    hotelService.value.deleteHotelContract(contract_id).then((data) => {
                        if(!data.errorResponse){
                            getHotelContract()
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});        
                        }
                    })
                }
            });
        }

        const editContract = (data) => {
            contractform_type.value = 'update'
            selectedHotelContract.value = data
            contract_form.contract_name = data.contract_name
        }

        const cancelContractUpdate = () =>{
            contractform_type.value = 'add'
            v_contractform$.value.$reset()
            initContractForm()
        }

        const initContractForm = () => {
            const contractForm = {
                contract_name: '',
                hotel_id: 0
            }

            Object.assign(contract_form, contractForm)
        }

        //Form Validation
        const contractform_rules = computed(() => {
            return {
                contract_name: { required },
            }            
        })
        const v_contractform$ = useVuelidate(contractform_rules, contract_form)
        const validateContractForm = () => {
            v_contractform$.value.$validate();
            if(!v_contractform$.value.$error){
                return true
            } else {
                return false
            }
        }

        //==============CONTRACT PERIOD==============
        let contractPeriodList = ref([])
        let selectedContractPeriod= ref()        
        let contractperiodform_type = ref('add')
        let contract_period_form = reactive({})
        let isDateValid = ref({isInvalid: false, message: ''})
        let contractRateTitle = ref()

        function getContractPeriod(contract_id) {
            hotelService.value.getContractPeriod(contract_id).then(data => {
                contractPeriodList.value = data
            })
        }

        const onContractPeriodRowSelect = () => {
            cancelContractPeriodUpdate()
            seasonalDropDown.value = [
                {id: 1, season: 'Low Season'},
                {id: 2, season: 'High Season'},
                {id: 3, season: 'Peak Season'}]
            selectedSeason.value = seasonalDropDown.value[0]

            contractRateTitle.value = selectedHotelContract.value.contract_name + ' [' + selectedContractPeriod.value.valid_from + ' - ' + selectedContractPeriod.value.valid_to + ']'

            addMoreRoomButton_Disable.value = false
            saveRoomRateButton_Disable.value = false
        }

        const addContractPeriod = () => {
            if(selectedHotelContract.value.id != 0) {
                if(validateForm() && validateContractPeriodDate()) {
                    const validFrom = dayjs(contract_period_form.valid_from).format('DD-MMM-YYYY')
                    const validTo =  dayjs(contract_period_form.valid_to).format('DD-MMM-YYYY')

                    contract_period_form.valid_from = validFrom
                    contract_period_form.valid_to = validTo
                    contract_period_form.contract_id = selectedHotelContract.value.id
                    hotelService.value.addContractPeriod(contract_period_form).then(data => {
                        if(!data.errorResponse) {
                            initContractPeriodForm()
                            getContractPeriod(selectedHotelContract.value.id)
                            v_contractperiod$.value.$reset() //Reset validations
                        } else {
                            toast.add({severity:'warn', summary: 'Add Contract Period Error '+data.status, detail: data.errorResponse, life: 5000}); 
                        }
                    })
                }
            }
        }

        const updateContractPeriod = () => {
            if(selectedHotelContract.value.id != 0) {
                if(validateForm() && validateContractPeriodDate()) {
                    const validFrom = dayjs(contract_period_form.valid_from).format('DD-MMM-YYYY')
                    const validTo =  dayjs(contract_period_form.valid_to).format('DD-MMM-YYYY')

                    const id = selectedContractPeriod.value.id
                    contract_period_form.valid_from = validFrom
                    contract_period_form.valid_to = validTo
                    
                    hotelService.value.updateContractPeriod(id, contract_period_form).then(data => {
                        if(!data.errorResponse) {
                            initContractPeriodForm()
                            getContractPeriod(selectedHotelContract.value.id)
                            contractperiodform_type.value = 'add'
                            v_contractperiod$.value.$reset() //Reset validations
                        } else {
                            toast.add({severity:'warn', summary: 'Update Contract Period Error '+data.status, detail: data.errorResponse, life: 4000}); 
                        }
                    })   
                }
            }
        }

        const confirmDeleteContractPeriod = (data) => {
            confirm.require({
                message: 'Are you sure you want to delete this hotel contract period?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const id = data.id
                    hotelService.value.deleteContractPeriod(id).then((data) => {
                        if(!data.errorResponse){
                            getContractPeriod(selectedHotelContract.value.id)
                        } else {
                            toast.add({severity:'warn', summary: 'Delete Error '+data.status, detail: data.errorResponse, life: 5000});        
                        }
                    })
                }
            });
        }

        const editContractPeriod = (data) => {
            contractperiodform_type.value = 'update'
            selectedContractPeriod.value = data

            contract_period_form.valid_from = new Date(data.valid_from)
            contract_period_form.valid_to = new Date(data.valid_to)
        }

        const cancelContractPeriodUpdate = () =>{
            contractperiodform_type.value = 'add'
            initContractPeriodForm()
            v_contractperiod$.value.$reset()
            isDateValid.value = {isInvalid: false, message: ''}
        }

        const validateContractPeriodDate = () => {
            const validfrom = new Date(contract_period_form.valid_from)
            const validto = new Date(contract_period_form.valid_to)
            if(validfrom > validto) {
                isDateValid.value = {isInvalid: true, message: 'Selected date is invalid.'}
                return false
            } else {
                isDateValid.value = {isInvalid: false, message: ''}
                return true
            }
        }

        const initContractPeriodForm = () => {
            const contractPeriodForm = {
                valid_from: '',
                valid_to: '',
                contract_id: 0
            }            

            Object.assign(contract_period_form, contractPeriodForm)
        }

        //Form Validations
        const rules = computed(() => {
            return {
                valid_from: { required },
                valid_to: { required },
            }            
        })
        const v_contractperiod$ = useVuelidate(rules, contract_period_form)
        const validateForm = () => {
            v_contractperiod$.value.$validate();
            if(!v_contractperiod$.value.$error){
                return true
            } else {
                return false
            }
        }

        //==============Room Rate==============
        let seasonalDropDown = ref([])
        let selectedSeason = ref(seasonalDropDown.value[0])
        // let roomrate = ref([
        //     {room_type: '', sgl_fit: 0, sgl_git: 0, dbl_fit: 0, dbl_git: 0, trp_fit: 0, trp_git: 0,},
        // ])
        let roomrate = ref([])
        let editingRoomRate = ref([])
        let extrabed = ref(0)
        let old_extrabedvalue = ref(0)
        let extrabed_rate = {}

        let addMoreRoomButton_Disable = ref(true)
        let saveRoomRateButton_Disable = ref(true)

        const addMoreRoom = () => {
            roomrate.value.push({room_type: '', sgl_fit: 0, sgl_git: 0, dbl_fit: 0, dbl_git: 0, trp_fit: 0, trp_git: 0,})
        }

        const saveRoomRate = () => {
            if(editingRoomRate.value.length > 0) {
                let roomrate_data = editingRoomRate.value.map(item => {
                    return {
                        hotel_id: hotel_id,
                        room_type_id: item.room_type_id,
                        room_type: item.room_type,
                        sgl_fit: item.sgl_fit,
                        sgl_git: item.sgl_git,
                        dbl_fit: item.dbl_fit,
                        dbl_git: item.dbl_git,
                        trp_fit: item.trp_fit,
                        trp_git: item.trp_git,
                        season: selectedSeason.value.season,
                        contract_period_id: selectedContractPeriod.value.id
                    }
                })
                
                hotelService.value.addRoomRate(roomrate_data).then(data => {
                    if(!data.errorResponse) {
                        toast.add({severity:'success', summary: 'Hotel Room Rate', detail: 'Room rate was successfully updated', life: 4000}); 
                        editingRoomRate.value.length = 0
                        getRoomRateData()
                    } else {
                        toast.add({severity:'warn', summary: 'Add Room Rate Error '+data.status, detail: data.errorResponse, life: 5000}); 
                    }
                })
            }

            //Save Hotel Extra Bed Rate
            if(old_extrabedvalue.value != extrabed.value) {
                extrabed_rate = {rate: extrabed.value, season: selectedSeason.value.season, contract_period_id: selectedContractPeriod.value.id}
                hotelService.value.addExtraBedRate(extrabed_rate).then(data  => {
                    if(!data.errorResponse) {
                        if(editingRoomRate.value.length == 0) { //Prevent double toast message
                            toast.add({severity:'success', summary: 'Hotel Room Rate', detail: 'Room rate was successfully updated', life: 4000}); 
                            getRoomRateData()
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Extra Bed Rate: '+data.status, detail: data.errorResponse, life: 5000}); 
                    }
                })
            }
            

            //Save Hotel Season Period
            if(validateSeasonPeriodDate()) {
                let period_data = selectedSeasonPeriod.value.map(item => {
                    return  {
                        id: item.id,
                        season: selectedSeason.value.season,
                        season_from: dayjs(item.season_from).format("YYYY-MM-DD"),
                        season_to: dayjs(item.season_to).format("YYYY-MM-DD"),
                        contract_period_id: selectedContractPeriod.value.id
                    }
                })
                console.log(period_data)
                hotelService.value.saveSeasonPeriod(period_data).then(data => {
                    if(!data.errorResponse) {
                        if(editingRoomRate.value.length == 0 || extrabed_rate == {}) { //Prevent double toast message
                            toast.add({severity:'success', summary: 'Hotel Room Rate', detail: 'Room rate was successfully updated', life: 4000}); 
                            getSeasonPeriod()
                            selectedSeasonPeriod.value.length = 0
                        }
                    } else {
                        toast.add({severity:'warn', summary: 'Season Period: '+data.status, detail: data.errorResponse, life: 5000}); 
                    }
                })
            }
        }

        watchEffect(() => {
            if(selectedSeason.value) {
                if(seasonalDropDown.value.length > 0) {
                    getRoomRateData()
                    getSeasonPeriod()
                }
            }
        })

        function getRoomRateData () {
            const season = selectedSeason.value.season
            const periodid = selectedContractPeriod.value.id

            hotelService.value.getRoomRate(hotel_id, periodid, season).then((data) => {
                if(data.length > 0) {
                    roomrate.value = data
                    extrabed.value = data[0].extra_bed
                    old_extrabedvalue.value = data[0].extra_bed //Use this variable to check if user enter new value
                }
            })
        }

        const onValueInput = (data, dataindex) => {
            // const index = editingRoomRate.value.findIndex(val => val.id == data.id)
            
            const index = editingRoomRate.value.findIndex(val => val.idx == dataindex)
            if(index == -1) {
                editingRoomRate.value = [...editingRoomRate.value, Object.assign(data, {idx: dataindex})]
            } else {
                // editingRoomRate.value[index] = Object.assign(data, {idx: dataindex})
            }
        }

        const confirmDeleteRoomRate = (data, data_index) => {
            confirm.require({
                message: 'Are you sure you want to delete "'+ data.room_type + '" rooms and its rate?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const room_type_id = data.room_type_id
                    if(room_type_id) {
                        hotelService.value.deleteRoomRate(room_type_id).then(data => {
                            if(!data.errorResponse){
                                roomrate.value = roomrate.value.filter(val => val.room_type_id !== room_type_id);
                            } else {
                                toast.add({severity:'error', summary: 'Delete Error ' + data.status, detail: data.errorResponse, life: 5000});        
                            }
                        })
                    } else {
                        roomrate.value.splice(data_index, 1)
                        editingRoomRate.value = editingRoomRate.value.filter(item => item.idx != data_index)
                    }
                }
            });
        }

        //==============Season Period==============
        // let season_periods = ref([
        //     {id: 1, season_from: '2021-09-01', season_to: '2021-09-21'},
        //     {id: 2, season_from: '2021-09-01', season_to: '2021-09-21'},
        // ])
        let season_periods = ref([])
        let periodDateValid = ref({})
        let selectedSeasonPeriod = ref([])

        const onSeasonPeriodChanged = (idx) => {            
            // let record_idx = 0
            // if(id) {
            //     record_idx = season_periods.value.findIndex(list => list.id == id)
            // } else {
            //     //If id doesn't exists = new record
            //     record_idx = season_periods.value.length - 1
            // }
            
            const index = selectedSeasonPeriod.value.findIndex(item => item.idx == idx)
            if(index == -1) {
                selectedSeasonPeriod.value =  [...selectedSeasonPeriod.value, 
                    Object.assign(season_periods.value[idx], {idx: idx})
                ]
                // Object.assign(selectedSeasonPeriod.value[record_idx], {idx: record_idx == -1 ? (newPeriodRecord-1) : record_idx})
            }
        }

        const addMorePeriodDate = () => {
            season_periods.value.push({season_from: '', season_to: '', idx: season_periods.value.length - 1})
        }

        const validateSeasonPeriodDate = () => {
            let validate = false
            if(selectedSeasonPeriod.value.length > 0) {
                selectedSeasonPeriod.value.forEach(date => {
                    const from_date = dayjs(date.season_from).format("DD-MMM-YYYY")
                    const to_date = dayjs(date.season_to).format("DD-MMM-YYYY")
                    
                    if(date.season_from == '' || date.season_to == '') {
                        periodDateValid.value.idx = date.idx
                        periodDateValid.value.message = 'Selected date is invalid.'
                        validate = false
                    } else if(dayjs(from_date).isAfter(to_date)) {
                        periodDateValid.value.idx = date.idx
                        periodDateValid.value.message = 'Selected date is invalid.'
                        validate = false
                    } else if(!dayjs(from_date).isBetween(selectedContractPeriod.value.valid_from, selectedContractPeriod.value.valid_to, null, '[]') || !dayjs(to_date).isBetween(selectedContractPeriod.value.valid_from, selectedContractPeriod.value.valid_to, null, '[]')){
                        periodDateValid.value.idx = date.idx
                        periodDateValid.value.message = 'Dates must be in between selected contract period.'
                        validate = false
                    } else {
                        periodDateValid.value = {}
                        validate = true
                    }
                })
            }
            return validate
        }

        const confirmDeleteSeasonPeriod = (seasonperiod_data, index) => {
            confirm.require({
                message: 'Are you sure you want to delete this season period "'+ seasonperiod_data.season_from + ' - ' + seasonperiod_data.season_to+'"?',
                header: 'Delete Confirmation',
                icon: 'pi pi-info-circle',
                acceptClass: 'p-button-danger',
                accept: () => {
                    const seasonperiod_id = seasonperiod_data.id
                    if(seasonperiod_id) {
                        hotelService.value.deleteSeasonPeriod(seasonperiod_id).then(data => {
                            if(!data.errorResponse){
                                season_periods.value = season_periods.value.filter(val => val.id !== seasonperiod_id);
                                getSeasonPeriod()
                            } else {
                                toast.add({severity:'error', summary: 'Season Period Error ' + data.status, detail: data.errorResponse, life: 5000});        
                            }
                        })
                    } else {
                        season_periods.value.splice(index, 1)
                    }
                }
            });
        }

        const getSeasonPeriod = () => {
            const season = selectedSeason.value.season
            const contractperiod_id = selectedContractPeriod.value.id
            hotelService.value.getSeasonPeriod(season, contractperiod_id).then(data => {
                // season_periods.value = data
                season_periods.value = data.map(item => {
                    return {
                        id: item.id,
                        season_from: item.season_from != '' ? new Date(item.season_from) : '',
                        season_to: item.season_to !=  '' ? new Date(item.season_to) : '',
                    }
                })
            })
        }

        // const initSeasonPeriodForm = () => {
        //     const seasonPeriodForm = {
        //         season_from: '',
        //         season_to: '',
        //     }            

        //     Object.assign(season_period_form, seasonPeriodForm)
        // }

        return {
            hotelname_header,

            v_contractform$,
            hotelContractList,
            selectedHotelContract,
            onHotelContractRowSelect,
            contract_form,
            contractform_type,
            addHotelContract,
            updateHotelContract,
            editContract,
            cancelContractUpdate,
            confirmDeleteHotelContract,

            // roomCategoryList,
            // selectedRoomCategoryList,
            // onRoomCategoryListRowSelect,

            v_contractperiod$,
            contractPeriodList,
            selectedContractPeriod,
            onContractPeriodRowSelect,
            contractperiodform_type,
            contract_period_form,
            addContractPeriod,
            updateContractPeriod,
            editContractPeriod,
            cancelContractPeriodUpdate,
            confirmDeleteContractPeriod,
            isDateValid,
            contractRateTitle,

            seasonalDropDown,
            selectedSeason,
            roomrate,
            addMoreRoom,
            saveRoomRate,
            editingRoomRate,
            onValueInput,
            confirmDeleteRoomRate,
            extrabed,

            addMoreRoomButton_Disable,
            saveRoomRateButton_Disable,

            season_periods,
            onSeasonPeriodChanged,
            addMorePeriodDate,
            validateSeasonPeriodDate,
            periodDateValid,
            confirmDeleteSeasonPeriod,
        }
    },
}
</script>

<style lang="scss" scoped>
.hotel-contract-form {
    width: 800px;
    margin-top: 0;
}
.hotel-contract-form .card {
    padding: 1rem 1rem;
}
.contract-input-bar .p-inputtext {
    width: 49rem;
    margin-right: .8rem;
}
</style>